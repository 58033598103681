import React, { useState } from 'react';
import R from 'ramda';
import PropTypes from 'prop-types';
import { Tabs } from '../../molecules/Tabs/Tabs';
import { generateVtoControlsClassNames } from '../../helpers';
import { AllShades } from '../AllShades/AllShades';
import { ShopNow } from '../../atoms/ShopNow';
import { DownloadPicture } from '../../atoms/DownloadPicture';
import { shopNowTracking, downloadPictureOpenTracking } from '../../../ShadeFinder/tracking';
import { getTrackingProduct } from '../../handlers';
import { vtoTypes } from '../../config';
import { MiniProductCardoList } from '../../molecules/MiniProductCardoList/MiniProductCardoList';

export const MakeupFinderVto = ({
  selectedProduct,
  vto,
  selectedProductSwatch,
  setActiveTab,
  setVtoActualProductSlug,
  vtoActualProductSku,
  onSetProductID,
  setVtoActualProductSku,
  swatches,
  isVtoLoaded,
  vtoType,
  setMakeupFinderFilter,
  setIsCartOpen,
  useShoppingCart,
  showBuyNowButton,
  showDownloadPictureButton,
  setShowTabs,
  defaultProductSku,
}) => {
  const showTabs = R.prop('showTabs', vto);
  const activeTab = R.prop('activeTab', vto);
  const makeupFinderFilter = R.prop('makeupFinderFilter', vto);
  const miniCardoList = R.path(['miniCardoList', vtoTypes.MAKEUP_FINDER], vto);
  const categoryFilteredMiniCardoList = activeTab
    ? R.filter(R.propEq('category', activeTab), miniCardoList)
    : miniCardoList;
  const filteredMiniCardoList = makeupFinderFilter
    ? R.filter(
        R.propEq('subcategory', makeupFinderFilter),
        categoryFilteredMiniCardoList
      )
    : categoryFilteredMiniCardoList;
  const filters = R.map(
    (filter) => ({
      label: filter,
      name: filter,
    }),
    R.uniq(R.map(R.prop('subcategory'), categoryFilteredMiniCardoList))
  );

  const selectedSwatchItemName = R.prop('itemName', selectedProductSwatch);
  const productUrl =
    !!selectedProduct &&
    `${R.prop('productUrl', selectedProduct)}${
      selectedProductSwatch ? `?shade=${selectedSwatchItemName}` : ''
    }`;

  return (
    <div
      className={generateVtoControlsClassNames({
        isVtoLoaded,
        extraClass: 'makeup-finder-vto',
      })}
      style={{
        minHeight: `${R.prop('vtoControlsHeight', vto)}px`,
      }}
    >
      <div className="vto__modal-button-wrapper">
        {!!productUrl && showBuyNowButton && (
          <ShopNow
            extraClass="vto__modal-shop-now-button"
            productUrl={useShoppingCart ? '' : productUrl}
            onClick={() => {
              const product = getTrackingProduct({
                selectedProductSwatch,
                swatches,
              });

              if (useShoppingCart) {
                setIsCartOpen(true);
              }

              if (!useShoppingCart && !!R.prop('item_id', product)) {
                shopNowTracking({
                  shadeName: selectedSwatchItemName,
                  product,
                  vtoType,
                });
              }
            }}
          />
        )}

        {R.path(['downloadPicture', 'downloadText'], vto) &&
          showDownloadPictureButton && (
            <DownloadPicture
              extraClass="vto__modal-download_picture-button"
              onClick={() => {
                if (window.YMK) {
                  try {
                    YMK.retake();

                    downloadPictureOpenTracking({
                      shadeName: R.prop('itemName', selectedProductSwatch),
                      vtoType,
                    });

                  } catch (error) {
                    console.error(error);
                  }
                }
              }}
            />
          )}
      </div>

      {showBuyNowButton && (
        <p className="makeup-finder-vto__shade-name">
          {selectedSwatchItemName}
        </p>
      )}

      {!showTabs && (
        <div className="vto-controls-wrapper">
          <AllShades
            defaultProductSku={defaultProductSku}
            vtoActualProductSku={vtoActualProductSku}
            onSetProductID={onSetProductID}
            setVtoActualProductSku={setVtoActualProductSku}
            swatches={swatches}
            enableRemoveSelectionSwatch
            showBackwardButton
            onBackwardButtonClick={() => {
              setShowTabs({ showTabs: true });
            }}
            vtoType={vtoType}
          />
        </div>
      )}

      {showTabs && (
        <Tabs
          variantTabs={R.path(['tabs', vtoType], vto)}
          activeVariantTab={activeTab}
          onClick={({ name }) => {
            setMakeupFinderFilter({ filter: '' });
            setActiveTab({ activeTab: name });
          }}
        />
      )}

      {showTabs && activeTab && (
        <Tabs
          variantTabs={filters}
          extraClass="makeup-finder__variant-tabs"
          tabExtraClass="filter-tab"
          activeVariantTab={makeupFinderFilter}
          onClick={({ name }) => {
            if (makeupFinderFilter === name) {
              setMakeupFinderFilter({ filter: '' });
            } else {
              setMakeupFinderFilter({ filter: name });
            }
          }}
        />
      )}

      {activeTab ? (
        <MiniProductCardoList
          miniProductCardoList={filteredMiniCardoList}
          selectedProduct={selectedProduct}
          onSelect={({ slug }) => {
            setVtoActualProductSlug({ actualProductSlug: slug });
            setShowTabs({ showTabs: false });
          }}
        />
      ) : (
        <div className="makeup-finder-vto__begin-statement">
          <p>{R.path(['makeupFinder', 'selectCategoryText'], vto)}</p>
        </div>
      )}
    </div>
  );
};

MakeupFinderVto.propTypes = {
  selectedProduct: PropTypes.object,
  vto: PropTypes.object,
  selectedProductSwatch: PropTypes.object,
  setActiveTab: PropTypes.func,
  setVtoActualProductSlug: PropTypes.string,
  vtoActualProductSku: PropTypes.string,
  onSetProductID: PropTypes.func,
  setVtoActualProductSku: PropTypes.func,
  swatches: PropTypes.arrayOf(PropTypes.object),
  isVtoLoaded: PropTypes.bool,
  vtoType: PropTypes.string,
  setMakeupFinderFilter: PropTypes.func,
  setIsCartOpen: PropTypes.func,
  useShoppingCart: PropTypes.bool,
  showBuyNowButton: PropTypes.bool,
  showDownloadPictureButton: PropTypes.bool,
  setShowTabs: PropTypes.func.isRequired,
  defaultProductSku: PropTypes.string,
};
