import React from 'react';
import PropTypes from 'prop-types';
import R from 'ramda';
import Picture from '@cotyorg/ccx-atoms/Picture';
import { isMobile, isBreakpointWithoutCTA } from './helpers';
import HeroFullBlockProductMobile from './shared/HeroFullBlockProductMobile';
import HeroFullBlockProductDesktop from './shared/HeroFullBlockProductDesktop';
import HeroFullBlockProductTablet from './shared/HeroFullBlockProductTablet';

const getView = ({ currentBreakpoint }) => {
  if (
    isMobile({ currentBreakpoint }) &&
    !isBreakpointWithoutCTA({ currentBreakpoint })
  ) {
    return HeroFullBlockProductTablet;
  }

  if (
    isMobile({ currentBreakpoint }) &&
    isBreakpointWithoutCTA({ currentBreakpoint })
  ) {
    return HeroFullBlockProductMobile;
  }
  return HeroFullBlockProductDesktop;
};

const HeroFullBlockProduct = ({ heroProduct, currentBreakpoint }) => {
  const Hero = getView({ currentBreakpoint });

  return (
    <div className="cell hero-full-block-product">
      <div>
        <Picture
          alt={R.path(['backgroundImage', 'alt'], heroProduct)}
          src={R.path(['backgroundImage', 'imageSrc'], heroProduct)}
          srcset={R.path(['backgroundImage', 'imageSrcSet'], heroProduct)}
        />
      </div>

      <Hero heroProduct={heroProduct} currentBreakpoint={currentBreakpoint} />
    </div>
  );
};

HeroFullBlockProduct.propTypes = {
  heroProduct: PropTypes.instanceOf(PropTypes.object).isRequired,
  currentBreakpoint: PropTypes.oneOf([
    'xsmall',
    'small',
    'medium',
    'large',
    'xlarge',
  ]).isRequired,
};

export default HeroFullBlockProduct;
