import React from 'react';
import PropTypes from 'prop-types';

const Canvas = ({ imageUrl, selectedProduct, selectedProductSwatch }) => {
  const canvas = React.useRef();

  const getLines = (ctx, text, maxWidth) => {
    const words = text.split(' ');
    const lines = [];
    let currentLine = words[0];

    for (let i = 1; i < words.length; i += 1) {
      const word = words[i];
      const { width } = ctx.measureText(`${currentLine} ${word}`);
      if (width < maxWidth) {
        currentLine += ` ${word}`;
      } else {
        lines.push(currentLine);
        currentLine = word;
      }
    }
    lines.push(currentLine);
    return lines;
  };

  React.useEffect(() => {
    const context = canvas.current.getContext('2d');

    const img = new Image();

    img.onload = () => {
      const containerWidth = document.getElementById(
        'downloadPicturePopup'
      ).offsetWidth;
      const imageWidth = 0.8 * containerWidth;
      const imageHeight = 0.8 * (containerWidth / img.width) * img.height;

      canvas.current.width = imageWidth;
      canvas.current.height = imageHeight;

      context.drawImage(img, 0, 0, imageWidth, imageHeight);

      context.font = '14px Arial';
      context.textAlign = 'center';

      const arrayOfLinesProduct = getLines(
        context,
        selectedProduct.title.toUpperCase(),
        imageWidth - 50
      );

      context.font = '12px Arial';
      let arrayOfLinesProductSwatch = [];

      if (selectedProductSwatch && selectedProductSwatch.itemName) {
        arrayOfLinesProductSwatch = getLines(
          context,
          selectedProductSwatch.itemName.toUpperCase(),
          imageWidth - 50
        );
      }

      const rectHeight =
        10 +
        arrayOfLinesProductSwatch.length * 18 +
        arrayOfLinesProduct.length * 20;

      context.fillStyle = 'rgba(0, 0, 0, 0.3)';
      context.fillRect(0, imageHeight - rectHeight, imageWidth, rectHeight);

      context.fillStyle = '#ffffff';
      context.font = '14px Arial';

      for (let i = 0; i < arrayOfLinesProduct.length; i += 1) {
        context.fillText(
          arrayOfLinesProduct[i],
          imageWidth / 2,
          imageHeight -
            10 -
            arrayOfLinesProductSwatch.length * 18 -
            (arrayOfLinesProduct.length - 1 - i) * 20
        );
      }

      context.font = '12px Arial';
      if (selectedProductSwatch && selectedProductSwatch.itemName) {
        for (let i = 0; i < arrayOfLinesProductSwatch.length; i += 1) {
          context.fillText(
            arrayOfLinesProductSwatch[i],
            imageWidth / 2,
            imageHeight - 10 - (arrayOfLinesProductSwatch.length - 1 - i) * 18
          );
        }
      }
    };

    img.src = imageUrl;
  });

  return <canvas id="previewImage" ref={canvas} />;
};

Canvas.propTypes = {
  imageUrl: PropTypes.string.isRequired,
  selectedProduct: PropTypes.object,
  selectedProductSwatch: PropTypes.object,
};
export default Canvas;
