import R from 'ramda';

export const isXSmallBreakpoint = ({ currentBreakpoint }) =>
  currentBreakpoint && currentBreakpoint.includes('xsmall');

export const isSmallBreakpoint = ({ currentBreakpoint }) =>
  currentBreakpoint && currentBreakpoint.includes('small');

export const isMediumBreakpoint = ({ currentBreakpoint }) =>
  currentBreakpoint && currentBreakpoint.includes('medium');

export const isLargeBreakpoint = ({ currentBreakpoint }) =>
  currentBreakpoint && currentBreakpoint.includes('large');

export const truncate = (maxLen, str) => {
  if (!str) return '';

  const input = String(str);
  if (input.length <= maxLen) return input;
  return `${input.substr(0, input.lastIndexOf(' ', maxLen))}...`;
};

export const truncateCardText = ({ items, maxTextLength }) =>
  Array.isArray(items) &&
  items.map((item) => ({
    ...item,
    description: R.compose(
      R.curry(truncate)(maxTextLength),
      R.prop('description')
    )(item),
  }));

export const showBV = (locale = '') => {
  const hideBVLocales = [
    'zh-cn',
    'sv-se',
    'es-es',
    'it-it',
    'es-ar',
    'es-cl',
    'ru-ru',
  ];
  return !hideBVLocales.includes(locale);
};
