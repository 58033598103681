import R from 'ramda';
import classNames from 'classnames';

export const isBreakpointWithoutCTA = ({ currentBreakpoint }) =>
  R.contains(currentBreakpoint, ['xsmall']);

export const isMobile = ({ currentBreakpoint }) =>
  R.contains(currentBreakpoint, ['xsmall', 'small', 'medium']);

export const generateHeadingClassNames = ({ isGoldHeading }) =>
  classNames('hero-full-block-product__heading', {
    'hero-full-block-product__heading--gold': isGoldHeading,
  });

export const generateButtonClassNames = ({ isBlackButton, isWhiteButton }) =>
  classNames('hero-full-block-product__button', {
    'hero-full-block-product__button--black': isBlackButton,
    'hero-full-block-product__button--white': isWhiteButton,
  });
