import React from 'react';
import PropTypes from 'prop-types';
import className from 'classnames';

const generateSectionHeaderCssClass = ({ textAign, isBanner, extraClass }) =>
  className('section__header', {
    'section__header--left': textAign === 'left',
    'section__header--banner': isBanner,
    [extraClass]: extraClass,
  });

const SectionHeader = ({ children, textAign, isBanner, extraClass }) => (
  <header
    className={generateSectionHeaderCssClass({
      textAign,
      isBanner,
      extraClass,
    })}
  >
    {children}
  </header>
);

SectionHeader.propTypes = {
  children: PropTypes.node.isRequired,
  textAign: PropTypes.oneOf(['left']),
  extraClass: PropTypes.string,
  isBanner: PropTypes.bool,
};

export default SectionHeader;
