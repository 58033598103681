import { eventsNames } from '../helpers/eventsConstants';
import { VTO_VERSION } from '../helpers/constants';
import { trackingVtoTypes } from '../../VtoModal/helpers';

export const pushEvent = ({ event }) => {
  console.log(event);
  if (window && Array.isArray(window.dataLayer)) {
    window.dataLayer.push(event);
  }
};

export const pageViewTracking = ({ pageName, vtoType }) => {
  const event = {
    event: eventsNames.PAGE_VIEW,
    page_name: pageName,
    app_version: `${trackingVtoTypes[vtoType]} - ${VTO_VERSION}`,
  };

  pushEvent({ event });
};

export const privacyPolicyAgreeTracking = ({ vtoType }) => {
  const event = {
    event: eventsNames.BASIC_EVENT,
    event_category: 'privacy policy',
    event_action: 'privacy policy agree',
    event_label: 'privacy policy agree',
    page_name: 'privacy policy',
    app_version: `${trackingVtoTypes[vtoType]} - ${VTO_VERSION}`,
  };

  pushEvent({ event });
};

export const quizQuestionTracking = ({ questionIndex, answer, vtoType }) => {
  const questionsEventPayload = [
    {
      event: eventsNames.BASIC_EVENT,
      event_category: 'preferred finish',
      event_action: 'select preferred finish',
      event_label: answer,
      page_name: 'preferred finish',
      app_version: `${trackingVtoTypes[vtoType]} - ${VTO_VERSION}`,
    },
    {
      event: eventsNames.BASIC_EVENT,
      event_category: 'foundation coverage',
      event_action: 'select foundation coverage',
      event_label: answer,
      page_name: 'foundation coverage',
      app_version: `${trackingVtoTypes[vtoType]} - ${VTO_VERSION}`,
    },
    {
      event: eventsNames.BASIC_EVENT,
      event_category: 'additional benefit',
      event_action: 'select additional benefit',
      event_label: answer,
      page_name: 'additional benefit',
      app_version: `${trackingVtoTypes[vtoType]} - ${VTO_VERSION}`,
    },
  ];

  const event = questionsEventPayload[questionIndex];

  pushEvent({ event });
};

export const productInfoClickTracking = ({ lookName, vtoType }) => {
  const event = {
    event: eventsNames.BASIC_EVENT,
    event_category: 'try on',
    event_action: 'product info clicked',
    event_label: lookName,
    page_name: 'try on',
    app_version: `${trackingVtoTypes[vtoType]} - ${VTO_VERSION}`,
  };

  pushEvent({ event });
};

export const shadeSelectTracking = ({ shadeName, vtoType }) => {
  const event = {
    event: eventsNames.BASIC_EVENT,
    event_category: 'try on',
    event_action: 'shade selected',
    event_label: shadeName,
    page_name: 'try on',
    app_version: `${trackingVtoTypes[vtoType]} - ${VTO_VERSION}`,
  };

  pushEvent({ event });
};

export const shopNowTracking = ({ shadeName, product, vtoType }) => {
  const event = {
    event: eventsNames.SELECT_ITEM,
    event_category: 'currently trying on',
    event_action: 'select item',
    event_label: shadeName,
    items: [product],
    page_name: 'currently trying on',
    app_version: `${trackingVtoTypes[vtoType]} - ${VTO_VERSION}`,
  };

  pushEvent({ event });
};

export const buyNowListViewTracking = ({ products, vtoType }) => {
  const event = {
    event: eventsNames.VIEW_ITEM_LIST,
    event_category: 'currently trying on',
    event_action: 'currently trying on list viewed',
    items: products,
    page_name: 'currently trying on',
    app_version: `${trackingVtoTypes[vtoType]} - ${VTO_VERSION}`,
  };

  pushEvent({ event });
};

export const downloadPictureOpenTracking= ({ shadeName, vtoType }) => {
  const event = {
    event: eventsNames.SELECT_ITEM,
    event_category: 'currently trying on',
    event_action: 'vto download picture open',
    event_label: shadeName,
    page_name: 'currently trying on',
    app_version: `${trackingVtoTypes[vtoType]} - ${VTO_VERSION}`,
  };

  pushEvent({ event });
};

export const downloadPictureDownloadTracking = ({ shadeName, vtoType }) => {
  const event = {
    event: eventsNames.SELECT_ITEM,
    event_category: 'currently trying on',
    event_action: 'vto download picture download',
    event_label: shadeName,
    page_name: 'currently trying on',
    app_version: `${trackingVtoTypes[vtoType]} - ${VTO_VERSION}`,
  };

  pushEvent({ event });
};
