import React from 'react';
import PropTypes from 'prop-types';
import R from 'ramda';
import { connect } from 'react-redux';

const HeroFullBlockContainer = ({
  childComponent: ChildComponent,
  heroProduct,
  heroProductStoreDataKey,
  ...props
}) => (
  <ChildComponent
    heroProduct={heroProduct[heroProductStoreDataKey]}
    {...props}
  />
);

HeroFullBlockContainer.propTypes = {
  childComponent: PropTypes.func.isRequired,
  heroProduct: PropTypes.shape({
    backgroundImage: PropTypes.object,
    productImage: PropTypes.object,
    productBackgroundImage: PropTypes.object,
    heading: PropTypes.string,
    cta: PropTypes.object,
  }),
  heroProductStoreDataKey: PropTypes.string.isRequired,
};

const mapStateToProps = ({ content, breakpoints }) => ({
  heroProduct: R.prop('heroProduct', content),
  currentBreakpoint: R.prop('current', breakpoints),
});

export default connect(mapStateToProps)(HeroFullBlockContainer);
