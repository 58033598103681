export const modalStyles = {
  overlay: {
    zIndex: 20,
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
  },
  content: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  },
};
