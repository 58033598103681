import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import R from 'ramda';
import classNames from 'classnames';
import Heading from '@cotyorg/ccx-atoms/Heading';
import Link from '@cotyorg/ccx-atoms/Link';
import { HeroFullBlockQuarter } from '@cotyorg/ccx-organism-hero/index';
import { CardoCarousel } from '@cotyorg/ccx-organism-carousel';
import OptionalSection from '@cotyorg/ccx-utils/js/components/OptionalSection';
import { trackCarouselClickEvent } from '@cotyorg/ccx-utils/js/tracking/adobe-dtm/events/carousel';
import { VideoPlayer } from '@cotyorg/ccx-organism-video';
import {
  trackVideoPlayEvent,
  trackVideoMilestoneEvent,
} from '@cotyorg/ccx-utils/js/tracking/adobe-dtm/events/video';
import SectionHeader from '../../atoms/SectionHeader';
import StandardLayout from '../../templates/StandardLayout';
import BVProductRating from '../../molecules/BVProductRating';
import HeroFullBlockProduct from '../../organisms/HeroFullBlockProduct';
import ShadeFinder from '../../organisms/ShadeFinder/components/ShadeFinder';

const generateHeroBlockClassNames = (lang) =>
  classNames('hero-full-block--main', 'hero-full-block--home', lang);

const HomeComponent = ({
  hero,
  sectionHeadings,
  cardo,
  locale,
  heroProduct,
  videos,
  shadeFinder,
}) => {
  const [vtoOpen, setVtoOpen] = useState(false);
  const [vtoProductSlug, setVtoProductSlug] = useState();

  return (
    <StandardLayout>
      <OptionalSection
        renderCondition={!!R.prop('homeBanner', heroProduct)}
        section={() => (
          <div className={`${locale} home__banner`}>
            <HeroFullBlockProduct heroProductStoreDataKey="homeBanner" />
          </div>
        )}
      />

      <OptionalSection
        renderCondition={!!R.prop('hero', hero)}
        section={() => (
          <div className={generateHeroBlockClassNames(locale)}>
            <HeroFullBlockQuarter
              heroStoreDataKey="hero"
              isUnderlined={false}
              ctaType="button"
            />
          </div>
        )}
      />

      {!!R.prop('mainVideo', videos) && (
        <div className="home__video-main">
          <VideoPlayer
            muted
            lightPlayIcon={<></>}
            autoPlay
            videosStoreDataKey="mainVideo"
            showControls={false}
            config={{
              youtube: {
                playerVars: {
                  controls: 1,
                },
                embedOptions: {
                  host: 'https://www.youtube-nocookie.com',
                },
              },
              file: {
                attributes: {
                  controls: true,
                  controlslist: 'nodownload',
                },
              },
            }}
            onProgress={({ url, duration, progress, videoMilestone }) =>
              trackVideoMilestoneEvent({
                url,
                duration,
                progress,
                videoMilestone,
                title: R.path(['mainVideo', 'title'], videos),
              })
            }
            onPlay={({ url, duration, progress }) =>
              trackVideoPlayEvent({
                url,
                duration,
                progress,
                title: R.path(['mainVideo', 'title'], videos),
              })
            }
          />
        </div>
      )}

      <OptionalSection
        renderCondition={R.path(['latestArrivals', 'length'], cardo) > 0}
        section={() => (
          <div className="grid-container">
            <SectionHeader>
              <Heading
                extraClass="section__title section__title--with-link"
                level={2}
              >
                {R.path(['latestArrivals', 'heading'])(sectionHeadings)}
              </Heading>

              <p className="section__link-container">
                <Link
                  extraClass="section__link"
                  link={R.path(['latestArrivals', 'link', 'url'])(
                    sectionHeadings
                  )}
                >
                  {R.path(['latestArrivals', 'link', 'text'])(sectionHeadings)}
                </Link>
              </p>
            </SectionHeader>

            <div className="latest-arrivals">
              <CardoCarousel
                cardoProps={{
                  cardoStoreDataKey: 'latestArrivals',
                  isTitleUnderlined: false,
                  hasBackground: false,
                  isSmallTitle: true,
                  fullWidth: true,
                  headingLevel: 5,
                  showStarRating: false,
                  fixedHeight: true,
                  zoomOnHover: true,
                  hideDescription: true,
                  alignCenter: false,
                  renderCustomRating: ({ cardoIndex }) =>
                    BVProductRating({
                      bvid: R.path(['latestArrivals', cardoIndex, 'bvid'])(
                        cardo
                      ),
                    }),
                }}
                carouselProps={{
                  carouselsStoreDataKey: 'latestArrivalsCarousel',
                  headingLevel: 5,
                  rightChevronIconSrc: '/ccx-files/assets/arrow-right.svg',
                  leftChevronIconSrc: '/ccx-files/assets/arrow-left.svg',
                  onIndexChange: ({ type, index, isForward }) =>
                    trackCarouselClickEvent({
                      page: 'home',
                      carousel: 'latestArrivalsCarousel',
                      type,
                      index,
                      isForward,
                    }),
                }}
                cardsPerPageMap={{
                  xsmall: 1,
                  small: 2,
                  medium: 4,
                  large: 4,
                  xlarge: 4,
                }}
              />
            </div>
          </div>
        )}
      />

      {(!!R.prop('heroProduct2', heroProduct) ||
        !!R.prop('video1', videos)) && (
        <div className="grid-container container-no-gutters">
          <SectionHeader>
            <Heading
              extraClass="section__title section__title--with-subheading"
              level={2}
            >
              {R.path(['heroProduct2', 'heading'], sectionHeadings)}
            </Heading>

            <Heading extraClass="section__subheading" level={4}>
              {R.path(['heroProduct2', 'subheading'], sectionHeadings)}
            </Heading>
          </SectionHeader>

          {!!R.prop('heroProduct2', heroProduct) && (
            <HeroFullBlockProduct heroProductStoreDataKey="heroProduct2" />
          )}

          {!!R.prop('video1', videos) && (
            <div className="home__video">
              <VideoPlayer
                muted
                lightPlayIcon={<></>}
                autoPlay
                videosStoreDataKey="video1"
                showControls={false}
                config={{
                  youtube: {
                    playerVars: {
                      controls: 1,
                    },
                    embedOptions: {
                      host: 'https://www.youtube-nocookie.com',
                    },
                  },
                }}
                onProgress={({ url, duration, progress, videoMilestone }) =>
                  trackVideoMilestoneEvent({
                    url,
                    duration,
                    progress,
                    videoMilestone,
                    title: R.path(['video1', 'title'], videos),
                  })
                }
                onPlay={({ url, duration, progress }) =>
                  trackVideoPlayEvent({
                    url,
                    duration,
                    progress,
                    title: R.path(['video1', 'title'], videos),
                  })
                }
              />
            </div>
          )}
        </div>
      )}

      {!!R.prop('heroProduct4', heroProduct) && (
        <div className="grid-container container-no-gutters">
          <SectionHeader>
            <Heading
              extraClass="section__title section__title--with-subheading"
              level={2}
            >
              {R.path(['heroProduct4', 'heading'], sectionHeadings)}
            </Heading>

            <Heading extraClass="section__subheading" level={4}>
              {R.path(['heroProduct4', 'subheading'], sectionHeadings)}
            </Heading>
          </SectionHeader>

          {!!R.prop('heroProduct4', heroProduct) && (
            <HeroFullBlockProduct heroProductStoreDataKey="heroProduct4" />
          )}
        </div>
      )}

      {(!!R.prop('spotlightOnArtistry', hero) ||
        !!R.prop('video1', videos)) && (
        <div className="grid-container container-no-gutters">
          <SectionHeader>
            <Heading extraClass="section__title" level={2}>
              {R.path(['spotlightOnArtistry', 'heading'], sectionHeadings)}
            </Heading>
            <OptionalSection
              renderCondition={
                !!R.path(['spotlightOnArtistry', 'subheading'], sectionHeadings)
              }
              section={() => (
                <Heading extraClass="section__subheading" level={4}>
                  {R.path(
                    ['spotlightOnArtistry', 'subheading'],
                    sectionHeadings
                  )}
                </Heading>
              )}
            />
          </SectionHeader>

          {!!R.prop('spotlightOnArtistry', hero) && (
            <div className="hero--gold-introduction">
              <HeroFullBlockQuarter
                heroStoreDataKey="spotlightOnArtistry"
                isUnderlined={false}
              />
            </div>
          )}

          {!!R.prop('video2', videos) && (
            <div className="home__video">
              <VideoPlayer
                muted
                lightPlayIcon={<></>}
                autoPlay
                videosStoreDataKey="video2"
                showControls={false}
                config={{
                  youtube: {
                    playerVars: {
                      controls: 1,
                    },
                    embedOptions: {
                      host: 'https://www.youtube-nocookie.com',
                    },
                  },
                }}
                onProgress={({ url, duration, progress, videoMilestone }) =>
                  trackVideoMilestoneEvent({
                    url,
                    duration,
                    progress,
                    videoMilestone,
                    title: R.path(['video2', 'title'], videos),
                  })
                }
                onPlay={({ url, duration, progress }) =>
                  trackVideoPlayEvent({
                    url,
                    duration,
                    progress,
                    title: R.path(['video2', 'title'], videos),
                  })
                }
              />
            </div>
          )}
        </div>
      )}

      {!!R.prop('heroProduct3', heroProduct) && (
        <div className="grid-container container-no-gutters">
          <SectionHeader>
            <Heading
              extraClass="section__title section__title--with-subheading"
              level={2}
            >
              {R.path(['heroProduct3', 'heading'], sectionHeadings)}
            </Heading>

            <Heading extraClass="section__subheading" level={4}>
              {R.path(['heroProduct3', 'subheading'], sectionHeadings)}
            </Heading>
          </SectionHeader>

          <HeroFullBlockProduct heroProductStoreDataKey="heroProduct3" />
        </div>
      )}

      <OptionalSection
        renderCondition={
          !!R.prop('heroSection3', sectionHeadings) ||
          !!R.prop('heroSection3', hero)
        }
        section={() => (
          <div className="grid-container container-no-gutters">
            <SectionHeader>
              <Heading
                extraClass="section__title section__title--with-subheading"
                level={2}
              >
                {R.path(['heroSection3', 'heading'], sectionHeadings)}
              </Heading>
              <Heading extraClass="section__subheading" level={4}>
                {R.path(['heroSection3', 'subheading'], sectionHeadings)}
              </Heading>
            </SectionHeader>

            <div className="hero--gold-introduction">
              <HeroFullBlockQuarter
                heroStoreDataKey="heroSection3"
                isUnderlined={false}
              />
            </div>
          </div>
        )}
      />

      <div className="grid-container">
        <SectionHeader>
          <Heading
            extraClass="section__title section__title--with-link"
            level={2}
          >
            {R.path(['mostPopularProducts', 'heading'], sectionHeadings)}
          </Heading>
          <p className="section__link-container">
            <Link
              extraClass="section__link"
              link={R.path(
                ['mostPopularProducts', 'link', 'url'],
                sectionHeadings
              )}
            >
              {R.path(['mostPopularProducts', 'link', 'text'], sectionHeadings)}
            </Link>
          </p>
        </SectionHeader>

        <div className="popular-products">
          <CardoCarousel
            cardoProps={{
              cardoStoreDataKey: 'mostPopularProducts',
              isTitleUnderlined: false,
              hasBackground: false,
              isSmallTitle: true,
              fullWidth: true,
              headingLevel: 5,
              showStarRating: false,
              fixedHeight: true,
              zoomOnHover: true,
              hideDescription: true,
              alignCenter: false,
              renderCustomRating: ({ cardoIndex }) =>
                BVProductRating({
                  bvid: R.path(['mostPopularProducts', cardoIndex, 'bvid'])(
                    cardo
                  ),
                }),
              additionalOnClick: shadeFinder
                ? ({ cardoIndex }) => {
                    setVtoOpen(true);
                    setVtoProductSlug(
                      R.path(['mostPopularProducts', cardoIndex, 'slug'])(cardo)
                    );
                  }
                : null,
            }}
            carouselProps={{
              carouselsStoreDataKey: 'mostPopularProductsCarousel',
              headingLevel: 5,
              rightChevronIconSrc: '/ccx-files/assets/arrow-right.svg',
              leftChevronIconSrc: '/ccx-files/assets/arrow-left.svg',
              onIndexChange: ({ type, index, isForward }) =>
                trackCarouselClickEvent({
                  page: 'home',
                  carousel: 'mostPopularProductsCarousel',
                  type,
                  index,
                  isForward,
                }),
            }}
            cardsPerPageMap={{
              xsmall: 1,
              small: 2,
              medium: 4,
              large: 4,
              xlarge: 4,
            }}
          />
        </div>
      </div>

      <OptionalSection
        renderCondition={!!R.prop('heroVoices', hero)}
        section={() => (
          <div className="grid-container container-no-gutters">
            <SectionHeader>
              <Heading extraClass="section__title" level={2}>
                {R.path(['maxFactorStories', 'heading'], sectionHeadings)}
              </Heading>
              <OptionalSection
                renderCondition={
                  !!R.path(['maxFactorStories', 'subheading'], sectionHeadings)
                }
                section={() => (
                  <Heading extraClass="section__subheading" level={4}>
                    {R.path(
                      ['maxFactorStories', 'subheading'],
                      sectionHeadings
                    )}
                  </Heading>
                )}
              />
            </SectionHeader>

            <div className="hero--gold-introduction">
              <HeroFullBlockQuarter
                heroStoreDataKey="heroVoices"
                isUnderlined={false}
              />
            </div>
          </div>
        )}
      />

      <OptionalSection
        renderCondition={
          !!R.prop('heroSection5', sectionHeadings) ||
          !!R.prop('heroSection5', hero)
        }
        section={() => (
          <div className="grid-container container-no-gutters">
            <SectionHeader>
              <Heading
                extraClass="section__title section__title--with-subheading"
                level={2}
              >
                {R.path(['heroSection5', 'heading'], sectionHeadings)}
              </Heading>
              <Heading extraClass="section__subheading" level={4}>
                {R.path(['heroSection5', 'subheading'], sectionHeadings)}
              </Heading>
            </SectionHeader>

            <div className="hero--gold-introduction">
              <HeroFullBlockQuarter
                heroStoreDataKey="heroSection5"
                isUnderlined={false}
              />
            </div>
          </div>
        )}
      />

      {!!shadeFinder && (
        <div className="home__section-try-on">
          <ShadeFinder
            shadeFinderConfiguration={shadeFinder}
            isOpen={vtoOpen}
            onClose={() => {
              setVtoOpen(false);

              if (window && window.YMK) {
                window.YMK.closeEngine();

                window.YMK = null;
              }
            }}
            vtoActualProductSlug={vtoProductSlug}
          />
        </div>
      )}
    </StandardLayout>
  );
};

HomeComponent.propTypes = {
  hero: PropTypes.objectOf(PropTypes.object),
  sectionHeadings: PropTypes.objectOf(PropTypes.object),
  cardo: PropTypes.objectOf(PropTypes.array),
  locale: PropTypes.string,
  heroProduct: PropTypes.objectOf(PropTypes.object),
  videos: PropTypes.objectOf(PropTypes.object),
  shadeFinder: PropTypes.object,
};

export default connect(({ content, locale }) => ({
  hero: R.prop('hero', content),
  cardo: R.prop('cardo', content),
  sectionHeadings: R.prop('sectionHeadings', content),
  locale,
  heroProduct: R.prop('heroProduct', content),
  videos: R.prop('videos', content),
  shadeFinder: R.prop('shadeFinder')(content),
}))(HomeComponent);
