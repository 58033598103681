import React, { useState } from 'react';
import R from 'ramda';
import PropTypes from 'prop-types';
import { Tabs } from '../../molecules/Tabs/Tabs';
import { generateVtoControlsClassNames } from '../../helpers';
import { BestMatch } from '../BestMatch/BestMatch';
import { AllShades } from '../AllShades/AllShades';
import { ProductSelect } from '../ProductSelect/ProductSelect';
import { ShopNow } from '../../atoms/ShopNow';
import { DownloadPicture } from '../../atoms/DownloadPicture';
import { shopNowTracking, downloadPictureOpenTracking } from '../../../ShadeFinder/tracking';
import { getTrackingProduct } from '../../handlers';
import { vtoTypes } from '../../config';

export const ShadeFinderVto = ({
  selectedProduct,
  vto,
  selectedProductSwatch,
  setActiveTab,
  setVtoActualProductSlug,
  vtoActualProductSku,
  onSetProductID,
  setVtoActualProductSku,
  swatches,
  isVtoLoaded,
  vtoType,
  useShoppingCart,
  setIsCartOpen,
  showBuyNowButton,
  showDownloadPictureButton,
  defaultProductSku,
}) => {
  const [isProductSelectOpen, setIsProductSelectOpen] = useState(false);

  const activeTab = R.prop('activeTab', vto);
  const miniCardoList = R.path(['miniCardoList', vtoTypes.SHADE_FINDER], vto);
  const productSelect = R.prop('productSelect', vto);
  const selectedSwatchItemName = R.prop('itemName', selectedProductSwatch);
  const { bestMatchProduct, bestMatchSwatches } = R.pick(
    ['bestMatchProduct', 'bestMatchSwatches'],
    R.prop('bestMatch', vto)
  );
  const productUrl =
    !!selectedProduct &&
    `${R.prop('productUrl', selectedProduct)}${
      selectedProductSwatch ? `?shade=${selectedSwatchItemName}` : ''
    }`;

  return (
    <div
      className={generateVtoControlsClassNames({
        isVtoLoaded,
        extraClass: 'shade-finder-vto',
      })}
      style={{
        height: `${R.prop('vtoControlsHeight', vto)}px`,
      }}
    >
      <div className="vto__modal-button-wrapper">
        {!!productUrl && showBuyNowButton && (
          <ShopNow
            extraClass="vto__modal-shop-now-button"
            productUrl={useShoppingCart ? null : productUrl}
            onClick={() => {
              const product = getTrackingProduct({
                selectedProductSwatch,
                swatches,
              });

              if (useShoppingCart) {
                setIsCartOpen(true);
              }

              if (!useShoppingCart && !!R.prop('item_id', product)) {
                shopNowTracking({
                  shadeName: selectedSwatchItemName,
                  product,
                  vtoType,
                });
              }
            }}
          />
        )}

        {R.path(['downloadPicture', 'downloadText'], vto) &&
          showDownloadPictureButton && (
            <DownloadPicture
              extraClass="vto__modal-download_picture-button"
              onClick={() => {
                if (window.YMK) {
                  try {
                    YMK.retake();

                    downloadPictureOpenTracking({
                      shadeName: R.prop('itemName', selectedProductSwatch),
                      vtoType,
                    });
                  } catch (error) {
                    console.error(error);
                  }
                }
              }}
            />
          )}
      </div>

      {showBuyNowButton && (
        <p className="shade-finder-vto__shade-name">{selectedSwatchItemName}</p>
      )}

      <Tabs
        variantTabs={R.path(['tabs', vtoType], vto)}
        activeVariantTab={activeTab}
        onClick={({ name }) => setActiveTab({ activeTab: name })}
      />

      <ProductSelect
        productSelect={productSelect}
        onSelect={({ slug }) => {
          setVtoActualProductSlug({ actualProductSlug: slug });
          setActiveTab({ activeTab: 'all-shades' });
          setIsProductSelectOpen(false);
        }}
        onOpen={() => setIsProductSelectOpen(true)}
        onClose={() => setIsProductSelectOpen(false)}
        selectedProduct={selectedProduct}
        miniProductCardoList={miniCardoList}
        wrapperExtraClass="vto-controls-wrapper flex-container align-spaced"
        vtoType={vtoType}
      />

      {!isProductSelectOpen && (
        <>
          {activeTab === 'your-match' && (
            <div className="vto-controls-wrapper">
              <BestMatch
                selectedProduct={bestMatchProduct}
                vtoActualProductSku={vtoActualProductSku}
                onSetProductID={onSetProductID}
                setVtoActualProductSku={setVtoActualProductSku}
                swatches={bestMatchSwatches}
                setVtoActualProductSlug={setVtoActualProductSlug}
                vtoType={vtoType}
              />
            </div>
          )}

          {activeTab === 'all-shades' && (
            <AllShades
              defaultProductSku={defaultProductSku}
              vtoActualProductSku={vtoActualProductSku}
              onSetProductID={onSetProductID}
              setVtoActualProductSku={setVtoActualProductSku}
              swatches={swatches}
              vtoType={vtoType}
            />
          )}
        </>
      )}
    </div>
  );
};

ShadeFinderVto.propTypes = {
  selectedProduct: PropTypes.object,
  vto: PropTypes.object,
  selectedProductSwatch: PropTypes.object,
  setActiveTab: PropTypes.func,
  setVtoActualProductSlug: PropTypes.string,
  vtoActualProductSku: PropTypes.string,
  onSetProductID: PropTypes.func,
  setVtoActualProductSku: PropTypes.func,
  swatches: PropTypes.arrayOf(PropTypes.object),
  isVtoLoaded: PropTypes.bool,
  vtoType: PropTypes.string,
  setIsCartOpen: PropTypes.func,
  useShoppingCart: PropTypes.bool,
  showBuyNowButton: PropTypes.bool,
  showDownloadPictureButton: PropTypes.bool,
  defaultProductSku: PropTypes.string,
};
