import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import R from 'ramda';
import Picture from '@cotyorg/ccx-atoms/Picture';
import Heading from '@cotyorg/ccx-atoms/Heading';
import Link from '@cotyorg/ccx-atoms/Link';
import OptionalSection from '@cotyorg/ccx-utils/js/components/OptionalSection';
import SectionHeader from '../../../atoms/SectionHeader';
import {
  generateHeadingClassNames,
  generateButtonClassNames,
} from '../helpers';

const HeroFullBlockProductTablet = ({ heroProduct, currentBreakpoint }) => (
  <div className="grid-container fluid hero-full-block-product__content-wrapper">
    <div className="grid-x hero-full-block-product__container">
      <div className="xsmall-offset-8 auto hero-full-block-product__packshot-wrapper">
        <OptionalSection
          renderCondition={
            R.path(['productBackgroundImage', 'imageSrc'], heroProduct) ||
            R.path(['productImage', 'imageSrc'], heroProduct)
          }
          section={() => (
            <>
              <OptionalSection
                renderCondition={R.path(
                  ['productBackgroundImage', 'imageSrc'],
                  heroProduct
                )}
                section={() => (
                  <div className="hero-full-block-product__product-background-image">
                    <div className="grid-container fluid">
                      <div className="grid-x">
                        <div className="cell xsmall-offset-4 xsmall-8">
                          <Picture
                            alt={R.path(
                              ['productBackgroundImage', 'alt'],
                              heroProduct
                            )}
                            src={R.path(
                              ['productBackgroundImage', 'imageSrc'],
                              heroProduct
                            )}
                            srcset={R.path(
                              ['productBackgroundImage', 'imageSrcSet'],
                              heroProduct
                            )}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              />

              <OptionalSection
                renderCondition={R.path(
                  ['productImage', 'imageSrc'],
                  heroProduct
                )}
                section={() => (
                  <div
                    className="hero-full-block-product__product-image
                  hero-full-block-product__product-image--mobile"
                  >
                    <div className="grid-container fluid">
                      <div className="grid-x">
                        <div className="cell xsmall-offset-4 xsmall-8">
                          <Picture
                            alt={R.path(['productImage', 'alt'], heroProduct)}
                            src={R.path(
                              ['productImage', 'imageSrc'],
                              heroProduct
                            )}
                            srcset={R.path(
                              ['productImage', 'imageSrcSet'],
                              heroProduct
                            )}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              />
            </>
          )}
        />

        <OptionalSection
          renderCondition={!!R.path(['sectionHeading', 'heading'], heroProduct)}
          section={() => (
            <SectionHeader
              extraClass={generateHeadingClassNames({
                isGoldHeading: R.prop('isGoldHeader', heroProduct),
              })}
            >
              <Heading
                level={R.path(
                  ['config', 'heading', currentBreakpoint],
                  heroProduct
                )}
              >
                {R.path(['sectionHeading', 'heading'], heroProduct)}
              </Heading>
            </SectionHeader>
          )}
        />

        <OptionalSection
          renderCondition={
            !!R.path(['sectionHeading', 'subheading'], heroProduct)
          }
          section={() => (
            <SectionHeader extraClass="hero-full-block-product__subheading">
              <Heading
                level={R.path(
                  ['config', 'subheading', currentBreakpoint],
                  heroProduct
                )}
              >
                {R.path(['sectionHeading', 'subheading'], heroProduct)}
              </Heading>
            </SectionHeader>
          )}
        />

        <OptionalSection
          renderCondition={R.path(['link', 'url'], heroProduct)}
          section={() => (
            <div className="grid-container">
              <div className="grid-x">
                <div className="cell">
                  <Link
                    target={
                      R.path(['link', 'openInNewWindow'], heroProduct)
                        ? '_blank'
                        : ''
                    }
                    link={R.path(['link', 'url'], heroProduct)}
                    extraClass={generateButtonClassNames({
                      isBlackButton: R.prop('isBlackButton', heroProduct),
                      isWhiteButton: R.prop('isWhiteButton', heroProduct),
                    })}
                  >
                    {R.path(['link', 'text'], heroProduct)}
                  </Link>
                </div>
              </div>
            </div>
          )}
        />
      </div>
    </div>
  </div>
);

HeroFullBlockProductTablet.propTypes = {
  heroProduct: PropTypes.instanceOf(PropTypes.object).isRequired,
  currentBreakpoint: PropTypes.oneOf([
    'xsmall',
    'small',
    'medium',
    'large',
    'xlarge',
  ]).isRequired,
};

export default HeroFullBlockProductTablet;
