import React from 'react';
import PropTypes from 'prop-types';
import R from 'ramda';
import Picture from '@cotyorg/ccx-atoms/Picture';
import Heading from '@cotyorg/ccx-atoms/Heading';
import Link from '@cotyorg/ccx-atoms/Link';
import OptionalSection from '@cotyorg/ccx-utils/js/components/OptionalSection';
import SectionHeader from '../../../atoms/SectionHeader';
import {
  generateHeadingClassNames,
  generateButtonClassNames,
} from '../helpers';

const HeroFullBlockProductDesktop = ({ heroProduct, currentBreakpoint }) => (
  <div className="grid-container fluid hero-full-block-product__content-wrapper">
    <div className="grid-x hero-full-block-product__container">
      <div className="xsmall-offset-4 xsmall-8 medium-offset-6 medium-4 large-offset-5 large-6">
        <OptionalSection
          renderCondition={!!R.path(['sectionHeading', 'heading'], heroProduct)}
          section={() => (
            <SectionHeader
              extraClass={generateHeadingClassNames({
                isGoldHeading: R.prop('isGoldHeader', heroProduct),
              })}
            >
              <Heading
                level={R.path(
                  ['config', 'heading', currentBreakpoint],
                  heroProduct
                )}
              >
                {R.path(['sectionHeading', 'heading'], heroProduct)}
              </Heading>
            </SectionHeader>
          )}
        />

        <OptionalSection
          renderCondition={
            !!R.path(['sectionHeading', 'subheading'], heroProduct)
          }
          section={() => (
            <div className="grid-container">
              <div className="grid-x">
                <div className="cell large-offset-2 large-12">
                  <SectionHeader extraClass="hero-full-block-product__subheading">
                    <Heading
                      level={R.path(
                        ['config', 'subheading', currentBreakpoint],
                        heroProduct
                      )}
                    >
                      {R.path(['sectionHeading', 'subheading'], heroProduct)}
                    </Heading>
                  </SectionHeader>
                </div>
              </div>
            </div>
          )}
        />

        <OptionalSection
          renderCondition={!!R.path(['link', 'url'], heroProduct)}
          section={() => (
            <div className="grid-container">
              <div className="grid-x">
                <div className="cell">
                  <Link
                    target={
                      R.path(['link', 'openInNewWindow'], heroProduct)
                        ? '_blank'
                        : ''
                    }
                    link={R.path(['link', 'url'], heroProduct)}
                    extraClass={generateButtonClassNames({
                      isBlackButton: R.prop('isBlackButton', heroProduct),
                      isWhiteButton: R.prop('isWhiteButton', heroProduct),
                    })}
                  >
                    {R.path(['link', 'text'], heroProduct)}
                  </Link>
                </div>
              </div>
            </div>
          )}
        />
      </div>

      <OptionalSection
        renderCondition={
          R.path(['productBackgroundImage', 'imageSrc'], heroProduct) ||
          R.path(['productImage', 'imageSrc'], heroProduct)
        }
        section={() => (
          <div className="xsmall-4 medium-6 large-5 hero-full-block-product__packshot-wrapper">
            <OptionalSection
              renderCondition={R.path(
                ['productBackgroundImage', 'imageSrc'],
                heroProduct
              )}
              section={() => (
                <div className="hero-full-block-product__product-background-image">
                  <div className="grid-container fluid">
                    <div className="grid-x">
                      <div className="cell xsmall-offset-1 xsmall-14">
                        <Picture
                          alt={R.path(
                            ['productBackgroundImage', 'alt'],
                            heroProduct
                          )}
                          src={R.path(
                            ['productBackgroundImage', 'imageSrc'],
                            heroProduct
                          )}
                          srcset={R.path(
                            ['productBackgroundImage', 'imageSrcSet'],
                            heroProduct
                          )}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            />

            <OptionalSection
              renderCondition={R.path(
                ['productImage', 'imageSrc'],
                heroProduct
              )}
              section={() => (
                <div className="hero-full-block-product__product-image">
                  <div className="grid-container fluid">
                    <div className="grid-x">
                      <div className="cell xsmall-offset-2 xsmall-12">
                        <Picture
                          alt={R.path(['productImage', 'alt'], heroProduct)}
                          src={R.path(
                            ['productImage', 'imageSrc'],
                            heroProduct
                          )}
                          srcset={R.path(
                            ['productImage', 'imageSrcSet'],
                            heroProduct
                          )}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            />
          </div>
        )}
      />
    </div>
  </div>
);

HeroFullBlockProductDesktop.propTypes = {
  heroProduct: PropTypes.instanceOf(PropTypes.object).isRequired,
  currentBreakpoint: PropTypes.oneOf([
    'xsmall',
    'small',
    'medium',
    'large',
    'xlarge',
  ]).isRequired,
};

export default HeroFullBlockProductDesktop;
