import { updateContent } from '@cotyorg/ccx-server/modules/content/actions';

export const setShowTabs = ({ showTabs }) =>
  updateContent({
    key: 'vto',
    data: {
      showTabs,
    },
  });

export const setVtoActualProductSku = ({ actualProductSku }) =>
  updateContent({
    key: 'vto',
    data: {
      actualProductSku,
    },
  });

export const setShowBuyNowButton = ({ showBuyNowButton }) =>
  updateContent({
    key: 'vto',
    data: {
      showBuyNowButton,
    },
  });

export const setShowDownloadPictureButton = ({ showDownloadPictureButton }) =>
  updateContent({
    key: 'vto',
    data: {
      showDownloadPictureButton,
    },
  });

export const setCart = ({ cart }) =>
  updateContent({
    key: 'vto',
    data: {
      cart,
    },
  });

export const setMakeupFinderFilter = ({ filter }) =>
  updateContent({
    key: 'vto',
    data: {
      makeupFinderFilter: filter,
    },
  });

export const setVtoScriptLoaded = ({ vtoScriptLoaded }) =>
  updateContent({
    key: 'vto',
    data: {
      vtoScriptLoaded,
    },
  });

export const setVtoActualProductSlug = ({ actualProductSlug }) =>
  updateContent({
    key: 'vto',
    data: {
      actualProductSlug,
    },
  });

export const setVtoControlsHeight = ({ vtoControlsHeight }) =>
  updateContent({
    key: 'vto',
    data: {
      vtoControlsHeight,
    },
  });

export const setActiveTab = ({ activeTab }) =>
  updateContent({
    key: 'vto',
    data: {
      activeTab,
    },
  });

export const setBestMatch = ({ bestMatch }) =>
  updateContent({
    key: 'vto',
    data: {
      bestMatch,
    },
  });
