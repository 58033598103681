import R from 'ramda';

export const generateChannelAdvisorWidgetUrl = ({
  selectedSKU,
  url,
  caPid,
  locale,
  isVtoOpened,
}) => {
  if (locale === 'es-cl' && isVtoOpened) {
    return `${url}?pid=${caPid}&modelname=${selectedSKU}&type=vto`;
  }
  return `${url}?pid=${caPid}&modelname=${selectedSKU}`;
};

export const validateSku = R.replace(/^0*/, '');
