import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@cotyorg/ccx-molecules/IconButton';

export const DownloadPicture = ({ extraClass, onClick }) => (
  <IconButton
    extraClass={extraClass}
    onClick={onClick}
    iconSrc="/ccx-files/assets/download-icon.svg"
  />
);

DownloadPicture.propTypes = {
  extraClass: PropTypes.string,
  onClick: PropTypes.func,
};
