import React from 'react';
import R from 'ramda';
import PropTypes from 'prop-types';
import IconButton from '@cotyorg/ccx-molecules/IconButton';
import Button from '@cotyorg/ccx-atoms/Button';
import Canvas from './Canvas';
import { downloadPictureDownloadTracking } from '../../../../ShadeFinder/tracking';

export const DownloadingPicture = ({
  imageUrl,
  selectedProduct,
  selectedProductSwatch,
  vtoType,
  downloadText,
  retakeText,
  onCloseClick,
  onRetakeClick,
}) => (
  <div className="downloadPicturePopup" id="downloadPicturePopup">
    <div className="downloadPicturePopup__content">
      <IconButton
        extraClass="downloadPicturePopup__close-button"
        iconSrc="/ccx-files/assets/cross-black.svg"
        onClick={onCloseClick}
      />

      <Canvas
        imageUrl={imageUrl}
        selectedProduct={selectedProduct}
        selectedProductSwatch={selectedProductSwatch}
      />

      <div className="downloadPicturePopup__buttons">
        <Button extraClass="button--retake" onClick={onRetakeClick}>
          {retakeText}
        </Button>
        <Button
          extraClass="button--download"
          onClick={() => {
            const canvas = document.getElementById('previewImage');
            const image = canvas
              .toDataURL('image/png', 1.0)
              .replace('image/png', 'image/octet-stream');
            const link = document.createElement('a');

            link.download = `${selectedProduct.title.replaceAll(' ', '-')}.png`;
            link.href = image;
            link.click();
            downloadPictureDownloadTracking({
              shadeName: R.prop('itemName', selectedProductSwatch),
              vtoType,
            });
          }}
        >
          {downloadText}
        </Button>
      </div>
    </div>
  </div>
);

DownloadingPicture.propTypes = {
  imageUrl: PropTypes.string,
  selectedProduct: PropTypes.object,
  selectedProductSwatch: PropTypes.object,
  downloadText: PropTypes.string,
  retakeText: PropTypes.string,
  onCloseClick: PropTypes.func,
  onRetakeClick: PropTypes.func,
};
