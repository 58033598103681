import React from 'react';
import PropTypes from 'prop-types';

const BVProductRating = ({ bvid, disableSeo }) => (
  <div
    data-bv-show="inline_rating"
    data-bv-product-id={bvid}
    data-bv-seo={disableSeo ? 'false' : 'true'}
  />
);

BVProductRating.propTypes = {
  bvid: PropTypes.string,
  disableSeo: PropTypes.bool,
};

export default BVProductRating;
