import React from 'react';
import PropTypes from 'prop-types';
import { generateChannelAdvisorWidgetUrl, validateSku } from './helpers';
import { useAdobe } from './hooks/adobe';

const ChannelAdvisorWidget = ({
  selectedSKU,
  url,
  caPid,
  locale,
  isVtoOpened,
}) => {
  const validatedSku = validateSku(selectedSKU);

  useAdobe();

  return (
    <div className="channel-advisor-widget">
      <iframe
        title="channel-advisor-widget"
        scrolling="no"
        src={generateChannelAdvisorWidgetUrl({
          selectedSKU: validatedSku,
          url,
          caPid,
          locale,
          isVtoOpened,
        })}
      />
    </div>
  );
};

ChannelAdvisorWidget.propTypes = {
  selectedSKU: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  caPid: PropTypes.string.isRequired,
  locale: PropTypes.string,
  isVtoOpened: PropTypes.bool,
};

export default ChannelAdvisorWidget;
